import * as React from "react";
import Img from "gatsby-image";
import useMedia from "use-media";
import Link from "../../LocalizedLink";
import useLocale from "../../../contexts/locale";

export type Service = {
  uid: string;
  lang: string;
  data: {
    short_description: {
      text: string;
    };
    title: {
      text: string;
    };
    icon: {
      localFile: any;
    };
  };
};
interface IProps {
  services: Service[];
  data: {
    services: Array<{
      title: {
        text: string;
      };
      description: {
        text: string;
      };
    }>;
  };
}

const Services = (props: IProps) => {
  const { locale, i18n } = useLocale();
  const i18nData = (i18n as any)[locale];
  const data = props.data.services[0];
  const isMobile = useMedia({ maxWidth: 960 });
  return (
    <div
      id="services"
      className={`services-area ${isMobile ? "pt-50 pb-40" : "pt-120 pb-100"}`}
      style={{ backgroundImage: `url(/images/bg/bg-1.jpg)` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 offset-lg-2 offset-xl-2">
            <div
              className={`section-title text-center ${
                isMobile ? "mb-30" : "ml-50 mr-50 mb-80"
              }`}
            >
              <span className="border-left-1"></span>
              <span>{data.title.text}</span>
              <span className="border-right-1"></span>
              <h1>{data.description.text}</h1>
            </div>
          </div>
        </div>
        <div className="row">
          {props.services.map(service => (
            <div key={service.uid} className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="services-wrapper text-center">
                <div className="services-img">
                  <Img
                    fixed={service.data.icon.localFile.childImageSharp.fixed}
                  />
                </div>
                <div className="services-text">
                  <h3>{service.data.title.text}</h3>
                  <p>{service.data.short_description.text}</p>
                  <Link to={`/services/${service.uid}`}>
                    <span className="services-button">
                      {i18nData.readMore}{" "}
                      <i className="far fa-long-arrow-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
