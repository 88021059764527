import * as React from "react";
// import Img from "gatsby-image";
import useMedia from "use-media";

interface IProps {
  data: {
    about_us: Array<{
      title: {
        text: string;
      };
      description: {
        html: string;
      };
    }>;
    our_vision: Array<{
      title: {
        text: string;
      };
      description: {
        html: string;
      };
    }>;
    our_mission: Array<{
      title: {
        text: string;
      };
      description: {
        html: string;
      };
    }>;
    our_values: Array<{
      title: {
        text: string;
      };
      description: {
        html: string;
      };
    }>;
  };
}

const About = (props: IProps) => {
  const isMobile = useMedia({ maxWidth: 960 });
  const {
    about_us: aboutUs,
    our_mission: ourMission,
    our_vision: ourVision,
    our_values: ourValues,
  } = props.data;

  const [aboutData] = aboutUs;
  const [visionData] = ourVision;
  const [missionData] = ourMission;
  const [valuesData] = ourValues;

  return (
    <>
      <div
        id="about"
        className={`about-area ${isMobile ? "pt-30 pb-20" : "pt-130 pb-100"}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-6 mb-30">
              <div className="about-text">
                <span>{aboutData.title.text}</span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutData.description.html,
                  }}
                />
              </div>
            </div>
            <div className="col-6 mb-30">
              <div className="row">
                <div className="col-12">
                  <div className="about-text">
                    <span>{visionData.title.text}</span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: visionData.description.html,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="about-text">
                    <span>{missionData.title.text}</span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: missionData.description.html,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="about-text">
                    <span>{valuesData.title.text}</span>
                    <div
                      className="values"
                      dangerouslySetInnerHTML={{
                        __html: valuesData.description.html,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .values ul {
          padding-left: 1rem;
          font-size: 15px;
        }
        .values li {
          list-style: disc;
        }
        `}</style>
    </>
  );
};

export default About;
