import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Slider from "../components/Section/Slider";
import About from "../components/Section/About";
import Services, { Service } from "../components/Section/Services";
import CallToAction from "../components/Section/CallToAction";
import Impacts, { Impact } from "../components/Section/Impacts";
import Testimonials, { Testimonial } from "../components/Section/Testimonials";
import Contact from "../components/Section/Contact";

interface IProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    services: {
      nodes: Service[];
    };
    impacts: {
      nodes: Impact[];
    };
    testimonials: {
      nodes: Testimonial[];
    };
    home: {
      nodes: Array<any>;
    };
    contact: {
      nodes: Array<any>;
    };
  };
  location: Location;
  pageContext: { locale: "mn-mn" | "en-us" };
}

const Home = (props: IProps) => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;
  const homeData = data.home.nodes[0].data;

  return (
    <Layout {...props} title={siteTitle}>
      <SEO title={`Home`} />
      <Slider data={homeData} />
      <About data={homeData} />
      <Services data={homeData} services={data.services.nodes} />
      <CallToAction data={homeData} />
      <Impacts data={homeData} cases={data.impacts.nodes} />
      <Testimonials data={homeData} testimonials={data.testimonials.nodes} />
      <Contact data={data.contact} />
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query HomeQuery($locale: String) {
    site {
      siteMetadata {
        title
      }
    }
    home: allPrismicHomepage(filter: { lang: { eq: $locale } }) {
      nodes {
        lang
        data {
          banner {
            image {
              url
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            title: main_title {
              text
            }
            description {
              text
            }
            link_title {
              text
            }
            link {
              url
            }
          }
          about_us {
            title {
              text
            }
            description {
              html
            }
          }
          our_vision {
            title {
              text
            }
            description {
              html
            }
          }
          our_mission {
            title {
              text
            }
            description {
              html
            }
          }
          our_values {
            title {
              text
            }
            description {
              html
            }
          }
          services {
            title {
              text
            }
            description {
              text
            }
          }
          call_to_action {
            title {
              text
            }
            description {
              text
            }
            link {
              url
            }
            button_title {
              text
            }
            phone {
              text
            }
          }
          cases {
            title {
              text
            }
            description {
              text
            }
          }
          testimonials {
            title {
              text
            }
            description {
              text
            }
          }
        }
      }
    }
    services: allPrismicService(
      sort: { fields: data___showorder, order: ASC }
      filter: { lang: { eq: $locale } }
    ) {
      nodes {
        uid
        lang
        data {
          short_description {
            text
          }
          title {
            text
          }
          icon {
            localFile {
              childImageSharp {
                fixed(width: 83, height: 78) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
    impacts: allPrismicCases(filter: { lang: { eq: $locale } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    testimonials: allPrismicTestimonials(filter: { lang: { eq: $locale } }) {
      nodes {
        uid
        data {
          position {
            text
          }
          name {
            text
          }
          image {
            localFile {
              childImageSharp {
                fixed(width: 60, height: 60) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          body {
            text
          }
        }
      }
    }
    contact: allPrismicForms(filter: { lang: { eq: $locale } }) {
      nodes {
        uid
        data {
          title {
            text
          }
          description {
            text
            html
          }
          backgroundimage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          buttontitle {
            text
          }
          successmessage {
            text
          }
          errormessage {
            text
          }
          body {
            slice_type
            items {
              label {
                text
              }
              field_name {
                text
              }
              type
              placeholder {
                text
              }
            }
          }
        }
      }
    }
  }
`;
