import * as React from "react";
import Slider, { Settings } from "react-slick";

interface IProps {
  data: {
    banner: Array<{
      image: any;
      title: {
        text: string;
      };
      description: {
        text: string;
      };
      link_title: {
        text: string;
      };
      link: {
        url: string;
      };
    }>;
  };
}

const BannerSlider = (props: IProps) => {
  const settings: Settings = {
    dots: false,
    autoplay: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <button>
        <span className="far fa-long-arrow-right" />
      </button>
    ),
    prevArrow: (
      <button>
        <span className="far fa-long-arrow-left" />
      </button>
    ),
  };
  return (
    <div className="slider-area">
      <Slider {...settings} className="slider-active">
        {props.data.banner.map((banner, index) => (
          <div key={`banner-${index}`}>
            <div
              className="single-slider slider-height slider-overlay d-flex align-items-center"
              style={{ backgroundImage: `url(${banner.image.url})` }}
            >
              <div className="container">
                <div className="row ">
                  <div className="col-xl-8 col-lg-8">
                    <div className="slider-content">
                      <h1 data-animation="fadeInLeft" data-delay=".3s">
                        {banner.title.text}
                      </h1>
                      <p data-animation="fadeInLeft" data-delay=".5s">
                        {banner.description.text}
                      </p>
                      <div
                        className="slider-button"
                        data-animation="fadeInUp"
                        data-delay=".7s"
                      >
                        <a className="btn" href="#">
                          <span className="btn-text">
                            {banner.link_title.text}{" "}
                            <i className="far fa-long-arrow-right"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerSlider;
