import * as React from "react";
import Img from "gatsby-image";
import Slider, { Settings } from "react-slick";

export type Testimonial = {
  uid: string;
  data: {
    name: {
      text: string;
    };
    position: {
      text: string;
    };
    body: {
      text: string;
    };
    image: {
      localFile: any;
    };
  };
};
interface IProps {
  testimonials: Testimonial[];
  data: {
    testimonials: Array<{
      title: {
        text: string;
      };
      description: {
        text: string;
      };
    }>;
  };
}

const Testimonials = (props: IProps) => {
  const data = props.data.testimonials[0];
  const settings: Settings = {
    dots: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <button>
        <span className="far fa-long-arrow-right" />
      </button>
    ),
    prevArrow: (
      <button>
        <span className="far fa-long-arrow-left" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          draggable: true,
        },
      },
    ],
  };

  return (
    <div id="testimonials" className="client-area dark pt-120 pb-100 grey-bg">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title  mb-70">
              <span className="b-sm-left-2"></span>
              <span className="sub-t-left">{data.title.text}</span>
              <h1>{data.description.text}</h1>
            </div>
          </div>
        </div>
        <div className="row">
          <Slider {...settings} className="client-active owl-carousel">
            {props.testimonials.map(testimonial => (
              <div key={testimonial.uid} className="col-xl-12">
                <div className="client-say-wrapper mb-30">
                  <div className="client-say-text">
                    <p>{testimonial.data.body.text}</p>
                    {/* <div className="client-rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div> */}
                    <div className="clientsay-name">
                      <div className="client-say-img">
                        <Img
                          fixed={
                            testimonial.data.image.localFile.childImageSharp
                              .fixed
                          }
                        />
                      </div>
                      <div className="client-say-content">
                        <h4>{testimonial.data.name.text}</h4>
                        <span>{testimonial.data.position.text}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
